const App = () => {
  return (
    <>
      <div className="pusher">
        <div
          className="ui inverted vertical masthead center aligned segment"
          style={{ backgroundColor: "#789463" }}
        >
          <div className="ui two column stackable grid container">
            <h1
              className="ui column header inverted huge center middle aligned"
              style={{ color: "#dadfdc", fontSize: "400%" }}
            >
              Affective
              <br />
              Software
              <br />
              Engineering
            </h1>
            <div className="ui column">
              <img
                className="ui large image centered bottom aligned"
                src="assets/ase_logo.png"
                alt="ASE logo"
              />
            </div>
          </div>
        </div>
        <div className="ui fluid container">
          <img
            className="ui image centered"
            src="assets/bottom.png"
            alt="bottom border"
          />
        </div>

        <div className="ui basic segment">
          <div className="ui equal width grid ">
            <div className="row">
              <div
                className="ui column"
                style={{ backgroundColor: "#a74d20", color: "#dadfdc" }}
              >
                <div className="ui basic segment justified" style={{ maxWidth: "500px", float: "right" }}>
                  <h3
                    className="ui header large centered"
                    style={{ color: "#dadfdc" }}
                  >
                    Who are we?
                  </h3>
                  <p>
                    We are a group of computer scientists whose research focuses
                    on using Affective Computing methods in the field of
                    Software Engineering. Our interests include, but are not
                    limited to, the role of emotions in the software development
                    process, reusing datasets from Affective Computing
                    experiments as well as improving the teaching process of future
                    software engineers.
                  </p>
                </div>
              </div>
              <div
                className="ui column"
                style={{ backgroundColor: "#b8d4a3", color: "#333333" }}
              >
                <div className="ui basic segment justified" style={{ maxWidth: "500px" }}>
                  <h3
                    className="ui header large centered"
                    style={{ color: "#333333" }}
                  >
                    Collaboration
                  </h3>
                  <p>
                    We are open to any kind of cooperation.
                    Do you want to do research together? Prepare a paper? Submit a project proposal?
                    If you are conducting research at the intersection of the fields of
                    affective computing and software engineering feel free to
                    contact us via{" "}
                    <a href="mailto:michal.wrobel@pg.edu.pl">email</a>.
                    Take a look at our current research and projects and get in touch!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ui justified container padded segment"
          style={{ backgroundColor: "#f4d07e" }}
        >
          <h2 className="ui header center aligned">Research</h2>
          <h3>DevEmo dataset</h3>
          <div className="ui  container">
            <p>
            The DevEmo dataset consists of 217 video clips showing students' facial expressions as they solve 
            programming tasks. The recordings were collected in the participants' actual work environment. 
            The video clips are annotated with five labels, including four emotions (anger, confusion, 
            happiness and surprise) and a neutral state.
          </p>
          <p>
          In order to facilitate the annotation of a large number of video recordings, we have developed AffecTube.
          It is a comprehensive solution that leverages crowdsourcing to annotate videos directly on the 
          YouTube platform, resulting in ready-to-use emotion-annotated datasets. AffecTube provides a low-resource 
          environment with an intuitive interface and customizable options, making it a versatile tool applicable 
          not only to emotion annotation, but also to various video-based behavioral annotation processes.
          </p>
          <p>
              If you are interested in using the DevEmo dataset or AffecTube check out the projects' websites:
              <div className="ui list">
                <div className="item">
                  <i className="linkify icon"></i>
                  <div className="content">
                    <a href="https://devemo.affectivese.org/">DevEmo dataset website</a>
                  </div>
                </div>  
                <div className="item">
                  <i className="github icon"></i>
                  <div className="content">
                    <a href="https://github.com/AffecTube/AffecTube">AffecTube repository</a>
                  </div>
                </div> 
                <div className="item">
                  <i className="newspaper outline icon"></i>
                  <div className="content">
                    Journal papers:
                    <div className="ui bulleted list">
                      <div className="item">
                        <div className="content">
                          <a href="https://www.mdpi.com/2076-3417/13/6/3839">
                          DevEmo—Software Developers' Facial Expression Dataset
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="content">
                          <a href="https://doi.org/10.1016/j.softx.2023.101504">
                          AffecTube — Chrome extension for YouTube video affective annotations
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p>
          </div>

          <div className="ui divider"></div>
          <h3>Affective data reausability</h3>
          <div className="ui  container">
            <p>
              Modern research in Affective Computing, often rely on datasets
              containing experiments-originated data such as biosignals, video
              clips, or images. Moreover, conducting experiments with a vast
              number of participants to build datasets for Affective Computing
              research is time-consuming and expensive. Therefore, it is
              extremely important to provide solutions allowing one to (re)use
              data from a variety of sources, which usually demands data
              integration.
          </p>
            <p>
              In the research conducted so far, we have proposed the ROAD
              (Recording Ontology for Affective-related Datasets) ontology and
              its implementation with the graph knowledge base in the form of
              the GRISERA framework.
          </p>
            <p>
              If you are interested in our approach take a look at:
          <div className="ui list">
                <div className="item">
                  <i className="linkify icon"></i>
                  <div className="content">
                    <a href="http://road.affectivese.org/">ROAD ontology website</a>
                  </div>
                </div>
                <div className="item">
                  <i className="github icon"></i>
                  <div className="content">
                    <a href="https://github.com/GRISERA/grisera-framework">GRISERA repository</a>
                  </div>
                </div>
                <div className="item">
                  <i className="newspaper outline icon"></i>
                  <div className="content">
                    Journal papers:
                    <div className="ui bulleted list">
                      <div className="item">
                        <div className="content">
                          <a href="https://ieeexplore.ieee.org/abstract/document/10070753">
                          Ontological Modeling for Contextual Data Describing Signals Obtained from Electrodermal Activity for Emotion Recognition and Analysis
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="content">
                          <a href="https://ieeexplore.ieee.org/document/9635802">
                            Ontological Model for Contextual Data Defining Time Series for Emotion Recognition and Analysis
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="content">
                          <a href="https://www.mdpi.com/1424-8220/21/12/4035">Graph Representation Integrating Signals for Emotion Recognition and Analysis </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p>
          </div>

          <div className="ui divider"></div>

          <h3>Emotions in the software development process</h3>
          <div className="ui  container">
            <p>Emotions play an important role in any human being's life, including software developers. The aim of our research is to determine what emotions are optimal in the software development process, how to recognize them and how (if at all) to influence them. </p>

            <p>Publications:</p>
            <div className="ui list">
              <PaperJournal
                title="Emotions in the software development processs"
                url="https://ieeexplore.ieee.org/document/6577875"
              />
              <PaperJournal
                title="Emotion recognition and its application in software engineering"
                url="https://ieeexplore.ieee.org/document/6577877"
              />
              <PaperJournal
                title="The Impact of Lexicon Adaptation on the Emotion Mining From Software Engineering Artifacts"
                url="https://ieeexplore.ieee.org/document/9026961"
              />
              <PaperJournal
                title="Applicability of Emotion Recognition and Induction Methods to Study the Behavior of Programmers "
                url="https://www.mdpi.com/2076-3417/8/3/323"
              />
              <PaperJournal
                title="MaliciousIDE - Software Development Environment that Evokes Emotions"
                url="https://ieeexplore.ieee.org/document/8511217"
              />
              <PaperJournal
                title="Towards the participant observation of emotions in software development teams"
                url="https://ieeexplore.ieee.org/document/7733457"
              />

            </div>
            <div className="ui divider"></div>

            <h3>Affect-aware software engineering education</h3>
            <div className="ui  container">
              <p>With the Covid-19, the role of distance learning has increased. The aim of the research is to develop methods that, using emotion recognition techniques, will allow for more effective remote learning of programming. </p>
              <p>Publications:</p>
              <div className="ui list">
                <PaperJournal
                  title="Limitations of Emotion Recognition from Facial Expressions in e-Learning Context"
                  url="https://www.scitepress.org/Papers/2017/63579/63579.pdf"
                />
                <PaperJournal
                  title="MaliciousIDE - Software Development Environment that Evokes Emotions"
                  url="https://ieeexplore.ieee.org/document/8511217"
                />
              </div>
            </div>
            <div className="ui divider"></div>

            <h3>Affective loop in Socially Assistive Robotics as an intervention tool for children with autism</h3>
            <div className="ui  container">
              <p>EMBOA project entitled “Affective loop in Socially Assistive Robotics as an intervention tool for children with autism” is a research and didactic project that aims at enhancement of social robot intervention in children with autism with affective computing technologies. The project is implemented in years 2019-2022 under EU Erasmus Plus Strategic Partnership for Higher Education Programme.</p>
              <p>More information on project website: <a href="https://emboa.eu">emboa.eu</a></p>
            </div>

          </div>
        </div>
        <div
          className="ui text container segment"
          style={{ backgroundColor: "#b8d4a3" }}
        >
          <h3 className="ui header">Publications</h3>
          <div className="ui list">
          <PaperFull
                title="AffecTube — Chrome extension for YouTube video affective annotations"
                author="Kulas D., Wrobel MR"
                booktitle="SoftwareX"
                year="2023"
                url="https://doi.org/10.1016/j.softx.2023.101504"
              />
          <PaperFull
                title="DevEmo—Software Developers’ Facial Expression Dataset"
                author="Manikowska M., Sadowski D., Sowinski A., Wrobel MR"
                booktitle="Applied Sciences"
                year="2023"
                url="https://www.mdpi.com/2076-3417/13/6/3839"
              />
            <PaperFull
              title="Ontological Modeling for Contextual Data Describing Signals Obtained from Electrodermal Activity for Emotion Recognition and Analysis"
              author="Zawadzka T, Wierciński T, Waloszek W, Wróbel MR"
              booktitle="IEEE Access"
              year="2023"
              url="https://ieeexplore.ieee.org/abstract/document/10070753"
            />
            <PaperFull
              title="Graph Representation Integrating Signals for Emotion Recognition and Analysis"
              author="Zawadzka T, Wierciński T, Meller G, Rock M, Zwierzycki R, Wróbel MR"
              booktitle="Sensors"
              year="2021"
              url="https://www.mdpi.com/1424-8220/21/12/4035"
            />
            <PaperFull
              title="Ontological Model for Contextual Data Defining Time Series for Emotion Recognition and Analysis "
              author="Zawadzka T, Waloszek W, Karpus A, Zapałowska S, Wróbel MR"
              booktitle="IEEE Access "
              year="2021 "
              url="https://ieeexplore.ieee.org/document/9635802"
            />
            <PaperFull
              title="Robot-based intervention for children with autism spectrum disorder: a systematic literature review "
              author="Bartl-Pokorny  KD, Pykala M, Uluer P, Barkana DE, Baird A, Kose H, Zorcec T, Robins B, Schuller BW, Landowska A"
              booktitle="IEEE Access "
              year="2021 "
              url="https://ieeexplore.ieee.org/abstract/document/9635826"
            />
            <PaperFull
              title="The impact of lexicon adaptation on the emotion mining from software engineering artifacts "
              author="Wróbel MR"
              booktitle="IEEE Access "
              year="2020"
              url="https://ieeexplore.ieee.org/document/9026961"
            />
            <PaperFull
              title="Perceptions of Gender Diversity's impact on mood in software development teams"
              author="Blincoe K, Springer O, Wrobel MR"
              booktitle="IEEE Software"
              year="2019"
              url="https://ieeexplore.ieee.org/abstract/document/8716518"
            />
            <PaperFull
              title="MaliciousIDE - Software Development Environment that Evokes Emotions"
              author="Wrobel MR, Zielke AW"
              booktitle="2018 Federated Conference on Computer Science and Information Systems (FedCSIS)"
              year="2018"
              url="https://ieeexplore.ieee.org/document/8511217"
            />
            <PaperFull
              title="Applicability of emotion recognition and induction methods to study the behavior of programmers"
              author="Wrobel MR"
              booktitle="Applied Sciences"
              year="2018"
              url="https://www.mdpi.com/2076-3417/8/3/323"
            />
            <PaperFull
              title="Scoreboard Architectural Pattern and Integration of Emotion Recognition Results"
              author="Landowska A, Brodny G"
              booktitle="IEEE Access"
              year="2018"
              url="https://ieeexplore.ieee.org/abstract/document/8587148"
            />
            <PaperFull
              title="Towards new mappings between emotion representation models "
              author="Landowska A "
              booktitle="Applied Sciences "
              year="2018 "
              url="https://www.mdpi.com/2076-3417/8/2/274"
            />
            <PaperFull
              title="Towards the participant observation of emotions in software development teams"
              author="Wrobel MR"
              booktitle="2016 Federated Conference on Computer Science and Information Systems (FedCSIS)"
              year="2016"
              url="https://ieeexplore.ieee.org/document/7733457"
            />
            <PaperFull
              title="Comparison of selected off-the-shelf solutions for emotion recognition based on facial expressions"
              author="Brodny G, Kolakowska A, Landowska A, Szwoch M, Szwoch W, Wrobel MR"
              booktitle="2016 9th International Conference on Human System Interactions (HSI)"
              year="2016"
              url="https://ieeexplore.ieee.org/abstract/document/7529664"
            />
            <PaperFull
              title="Modeling emotions for affect-aware applications"
              author="Kolakowska A, Landowska A, Szwoch M, Szwoch W, Wrobel MR"
              year="2015"
              booktitle="Information Systems Development and Applications"
              url="http://wzr.ug.edu.pl/nauka/upload/files/Information%20systems%20development%20and%20applications.pdf#page=55"
            />
            <PaperFull
              title="Emotion recognition and its applications"
              author="Kolakowska A, Landowska A, Szwoch M, Szwoch W, Wrobel MR"
              booktitle="Human-Computer Systems Interaction: Backgrounds and Applications 3"
              year="2014"
              url="https://link.springer.com/chapter/10.1007/978-3-319-08491-6_5"
            />
            <PaperFull
              title="Emotion recognition and its application in software engineering"
              author="Kolakowska A, Landowska A, Szwoch M, Szwoch W, Wrobel MR"
              booktitle="6th International Conference on Human System Interactions (HSI)"
              year={2013}
              url="https://ieeexplore.ieee.org/abstract/document/6577877"
            />
            <PaperFull
              title="Emotions in the software development process"
              author="Wrobel MR"
              booktitle="6th International Conference on Human System Interactions (HSI)"
              year="2013"
              url="https://ieeexplore.ieee.org/document/6577875"
            />
          </div>
        </div>

        <div className="ui vertical footer segment">
          <div className="ui container">
            <div className="ui stackable equal height stackable grid">
              <div className="six wide column">
                <a href="https://www.pg.edu.pl/" alt="GDANSK Tech">
                  <img
                    className="ui tiny right floated image"
                    src="assets/logo_pg.png"
                    alt="GDANSK Tech"
                  />
                </a>
              </div>
              <div className="six wide column">
                <a href="https://www.eti.pg.edu.pl/" alt="ETI">
                  <img
                    className="ui tiny right floated image"
                    src="assets/logo.eti.png"
                    alt="WETI"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PaperJournal = ({ title, url }) => {

  return (
    <>
      <div className="item">
        <i className="newspaper outline icon"></i>
        <div className="content">
          <a href={url}>
            {title}
          </a>
        </div>

      </div>
    </>
  );
}

const PaperFull = ({ title, author, booktitle, year, url }) => {

  return (
    <>
      <div className="item">
        <i className="file alternate icon"></i>
        <div className="content">
          {author}, <a href={url} style={{ color: "navy" }}><cite>{title}</cite></a>, {booktitle}, {year}
        </div>
      </div>
    </>
  );
}

export default App;
